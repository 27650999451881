function randomColor(colors) {
  var color = colors[Math.floor(Math.random() * colors.length)];

  $('body').removeClass().addClass(color);
}

function updateCritique(critique) {
  $('h2').text(critique.h2);
  $('h1').text(critique.h1);
}

function randomCritique(critiques) {
  var critique = critiques[Math.floor(Math.random() * critiques.length)];

  updateCritique(critique);
}


function randomLink(links) {
  var link = links[Math.floor(Math.random() * links.length)];

  $('#critique a').attr('href',link);
}

function randomStyle(options) {
  var colors = options.colors;
  var links = options.links;

  randomColor(colors);
  randomLink(links);
}

function random() {
  $.get( "js/options.json" ).done(function(options){
    randomCritique(options.critiques);
    randomStyle(options);
  });
}

$( document ).ready(function() {
  random();
});

function generateCritique(critiqueVal) {
  $.get( "js/options.json" ).done(function(options){
    updateCritique(options.critiques[critiqueVal]);
    randomStyle(options);
  });
}

$('#right').click(function(){
  generateCritique(0);
});

$('#leaf').click(function(){
  generateCritique(1);
});

$('#but').click(function(){
  generateCritique(2);
});

$('#dont').click(function(){
  generateCritique(3);
});

$('#random').click(function() {
  random();
});
